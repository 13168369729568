/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import routes from '@utils/routes';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Button from '@components/Button';
import OfferBox from '@components/OfferBox';
import Preloader from '@components/Preloader';

import { Container, Wrapper, Offers as StyledOffers } from '@components/Offers/styles';

import OffersFilters from '@components/OffersFilters';

interface OffersProps {
  offers: OfferBox[];
  offersCount: number;
}

function Offers({ offers, offersCount }: OffersProps) {
  const { t } = useTranslation('offers');
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();

  async function onSubmit(query) {
    setIsLoading(true);
    const { offer } = query;

    const params = [];
    Object.keys(offer).forEach((key) => {
      if (offer[key]) {
        params.push(`${key}=${offer[key]}`);
      }
    });

    await router.push(`${routes.offers}?${params.join('&')}`);
  }

  if (!offers.length) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        {isLoading ? <Preloader overlay /> : null}
        <OffersFilters onSubmit={onSubmit} offersCount={offersCount} />

        <StyledOffers>
          {offers.map((offer: OfferBox) => (
            <OfferBox key={`offer-box_${offer.id}`} offer={offer} />
          ))}
        </StyledOffers>
        <Button href={routes.offers}>{t('Show More')}</Button>
      </Wrapper>
    </Container>
  );
}

export default Offers;
