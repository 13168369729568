/* eslint-disable import/no-unresolved */
import styled from '@emotion/styled';
import { Swiper } from 'swiper/react';
import { ContentWrapper, fontSize } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';
import RedImage from '@assets/images/red-employer.svg';
import YellowImage from '@assets/images/yellow-employer.svg';

export const Container = styled.section`
  background: #f7f7f8 url(${YellowImage.src}) no-repeat right 0 bottom 0 / contain;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url(${RedImage.src}) no-repeat left 0 bottom 0 / contain;
    position: absolute;
    z-index: 0;
  }

  @media screen and ${breakpoint.md} {
    background: #f7f7f8;

    &:before {
      background: none;
    }
  }
`;

export const Wrapper = styled(ContentWrapper)`
  padding: 120px 0 56px 0;
  position: relative;
  z-index: 2;
  text-align: center;

  h1 {
    text-align: center;
  }

  @media screen and (max-width: 1350px) {
    padding: 56px 15px;
  }

  @media screen and ${breakpoint.md} {
    > a {
      margin-top: 30px;
    }

    h1 {
      font-size: ${fontSize(32)};
    }
  }

  @media screen and ${breakpoint.sm} {
    padding: 50px 15px;
    > a {
      margin-top: 30px;
      width: 100%;
    }
  }
`;

export const SwiperWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 56px;

  .swiper-button-prev,
  .swiper-button-next {
    all: initial;
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &::after {
      all: initial;
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
  }

  @media screen and ${breakpoint.md} {
    margin-bottom: 10px;
  }
`;

export const StyledSwiper = styled(Swiper)`
  max-width: 1080px;
  position: relative;

  @media screen and ${breakpoint.xl} {
    width: 90%;
  }

  @media screen and ${breakpoint.md} {
    width: 85%;
  }

  @media screen and ${breakpoint.sm} {
    width: 75%;
  }
`;

export const Company = styled.a`
  height: 88px;
  margin-top: 0;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.div`
  position: relative;
  width: 90%;
  height: 80%;
`;
