import React, { useEffect } from 'react';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetServerSidePropsContext } from 'next';
import { useTranslation } from 'next-i18next';
import { API, APIRoutes, BANNER_TARGETS } from '@utils/api';
import MainBanner from '@components/PageBanner';
import Offers from '@components/Offers';
import News from '@components/News';
import Employers from '@components/EmployersCarousel';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import routes from '@utils/routes';

type HomepageProps = {
  banners: BannerItem[];
  offers: {
    offers: OfferBox[];
    meta: Meta;
  };
  news: NewsItem[];
  employers: Employer[];
};

function IndexPage({ banners, offers, news, employers }: HomepageProps) {
  const { t } = useTranslation(['homepage', 'common']);
  const { query, replace } = useRouter();

  useEffect(() => {
    if (query.confirmed === 'false') {
      toast.error(t("Account hasn't been confirmed", { ns: 'common' }));
      replace(routes.homepage, undefined, { shallow: true });
    }
  }, [query, replace, t]);

  return (
    <>
      <Head>
        <title>{t('page-title')}</title>
        <meta property="og:image" content={`${process.env.NEXT_PUBLIC_PORTAL_URL}/images/lodz.png`} />
        <meta property="og:image:secure_url" content={`${process.env.NEXT_PUBLIC_PORTAL_URL}/images/lodz.png`} />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="279" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Apprenticeship and internship portal" />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_PORTAL_URL}`} />
      </Head>
      <MainBanner bannerItems={banners} />
      <Offers offers={offers.offers} offersCount={offers.meta.pagination.totalOffers} />
      <News news={news} />
      <Employers employers={employers} />
    </>
  );
}

export async function getServerSideProps({ locale }: GetServerSidePropsContext) {
  try {
    const {
      data: { banners },
    } = await API.get(APIRoutes.banners(BANNER_TARGETS.HOMEPAGE));
    const { data: offers } = await API.get(APIRoutes.offers({ pageSize: 6, promoted: true }));

    const {
      data: { news },
    } = await API.get(APIRoutes.news);
    const {
      data: { employerProfiles },
    } = await API.get(APIRoutes.employers({ pageSize: 10, promoted: true }));

    return {
      props: {
        ...(await serverSideTranslations(locale, [
          'common',
          'homepage',
          'navbar',
          'footer',
          'offers',
          'news',
          'employers',
          'bottomBanner',
          'autocomplete',
        ])),
        banners,
        offers,
        news,
        employers: employerProfiles,
      },
    };
  } catch (error) {
    return { props: {} };
  }
}

export default IndexPage;
