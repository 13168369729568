import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { fontSize, ContentWrapper } from '@styles/globalStyles';
import blackLogo from '@assets/images/offer-black.svg';
import blueLogo from '@assets/images/offer-blue.svg';

export const Container = styled.section`
  width: 100%;
  padding: 72px 0;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f7f7f8;
  background-image: url(${blackLogo.src}), url(${blueLogo.src});
  background-repeat: no-repeat;
  background-position: left bottom -230px, right bottom 164px;

  @media only screen and ${breakpoint.md} {
    padding: 56px 0 48px 0;
    background-image: none;
  }

  @media only screen and ${breakpoint.sm} {
    padding: 32px 0 48px;
  }

  @media only screen and ${breakpoint.xs} {
    padding: 24px 0 32px;
  }
`;

export const Wrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  @media only screen and ${breakpoint.sm} {
    a {
      width: 100%;
    }
  }
`;

export const Filters = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and ${breakpoint.lg} {
    flex-wrap: wrap;
    gap: 16px;
  }

  @media only screen and ${breakpoint.md} {
    display: block;
    box-shadow: ${({ theme }) => theme.shadow.medium};
  }

  .simple-dropdown__container {
    width: 500px;
  }
`;

export const Accessories = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 24px 0;
  width: 100%;
`;

export const OffersCounter = styled.p`
  font-size: ${fontSize(18)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.2704px;
  color: #002330;
`;

export const ClearButton = styled.button`
  border: none;
  background-color: inherit;
  color: #002330;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ClearButtonIcon = styled.div`
  margin-right: 10px;
`;

export const Offers = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding-bottom: 56px;
`;
