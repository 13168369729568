/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import routes from '@utils/routes';
import { prepareLink } from '@utils/dataHelpers';
import { useRouter } from 'next/router';

import { SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

import TextHeader, { SIZE, TAG, WEIGHT } from '@components/TextHeader';
import Button from '@components/Button';
import EmployersFilters from '@components/EmployersFilers';
import { Container, Wrapper, StyledSwiper, SwiperWrapper, Company, Logo } from '@components/EmployersCarousel/styles';

import Arrow from '@assets/images/right-arrows.png';
import Mask from '@assets/images/mask-group.svg';

import Preloader from '@components/Preloader';

type EmployerFiltersData = {
  employer: {
    fields_of_expertise_id?: number | null;
    name: string | null;
  };
};

function Employers({ employers }) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('employers');

  const router = useRouter();

  let debounce: ReturnType<typeof setTimeout>;

  async function onSubmit(query: EmployerFiltersData) {
    clearTimeout(debounce);

    debounce = setTimeout(async () => {
      setIsLoading(true);
      const { employer } = query;

      const params = [];
      Object.keys(employer).forEach((key) => {
        if (employer[key]) {
          params.push(`${key}=${employer[key]}`);
        }
      });

      await router.push(`${routes.employer}?${params.join('&')}`);
    }, 300);
  }

  return (
    <Container>
      <Wrapper>
        <TextHeader size={SIZE.LG} weight={WEIGHT.NORMAL} tag={TAG.H1}>
          {t('employers')}
        </TextHeader>

        {isLoading ? <Preloader overlay /> : null}
        <EmployersFilters onSubmit={onSubmit} />

        <SwiperWrapper>
          <StyledSwiper
            slidesPerView={5}
            spaceBetween={30}
            autoplay={{ delay: 3000, disableOnInteraction: true }}
            loop
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            modules={[Navigation, Autoplay]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              340: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              520: {
                slidesPerView: 3,
              },
              770: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
            }}
          >
            {employers.map((employer: Employer) => (
              <SwiperSlide key={`employer-slide-item_${employer.id}`}>
                <Link href={prepareLink('employer', employer.slug, employer.id)}>
                  <Company href={prepareLink('employer', employer.slug, employer.id)}>
                    <Logo>
                      <Image
                        src={employer.logo.desktop || Mask}
                        alt={employer.name}
                        layout="fill"
                        objectFit="contain"
                      />
                    </Logo>
                  </Company>
                </Link>
              </SwiperSlide>
            ))}
          </StyledSwiper>
          <button type="button" className="swiper-button-prev">
            <Image src={Arrow} />
          </button>
          <button type="button" className="swiper-button-next">
            <Image src={Arrow} />
          </button>
        </SwiperWrapper>
        <Button href={routes.employer}>{t('show more')}</Button>
      </Wrapper>
    </Container>
  );
}

export default Employers;
